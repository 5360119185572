import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { useEffect } from 'react';
import { useAppSelector } from 'state/store';
import { getAccessToken } from 'state/customer/customer.slice';
import { useNavigate } from 'react-router-dom';
import { useGetOrdersHistory } from 'hooks/useGetOrdersHistory';

export const useOrdersHistoryVM = () => {
    const { goLogin } = useWorkflowStateMachine();
    const accessToken = useAppSelector(getAccessToken);
    const { data: orders, isLoading } = useGetOrdersHistory();

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const isAuthenticated = !!accessToken;
        if (!isAuthenticated && goLogin) goLogin({});
    }, [accessToken, goLogin]);

    return { goBack: handleGoBack, orders, isLoading };
};
