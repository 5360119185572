import { useAppSelector } from 'state/store';
import { getAccessToken, getCustomerId } from 'state/customer/customer.slice';
import { useGetOrdersHistoryQuery } from 'services/order/order.endpoints';
import { OmnichannelOrder } from 'services/order/order.type';

export type OrdersHistory = {
    data?: OmnichannelOrder[];
    isLoading: boolean;
};

export const useGetOrdersHistory = (): OrdersHistory => {
    const accessToken = useAppSelector(getAccessToken);
    const customerId = useAppSelector(getCustomerId);

    const { data, isLoading } = useGetOrdersHistoryQuery(
        {
            accessToken: accessToken!,
            customerId: customerId?.toString() || '',
        },
        { skip: !accessToken || !customerId },
    );

    return { data, isLoading };
};
